import styled from 'styled-components';
import icon from '../../assets/hamburger-icon.svg';

export const Nav = styled.nav`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  background: linear-gradient(
    89.99deg,
    rgba(255, 255, 255, 0.019) 0.01%,
    rgba(255, 255, 255, 0.02) 100.51%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(40px);
  z-index: 1000;
  @media (max-width: 1140px) {
    justify-content: center;
  }
`;

export const NavSpaceContent = styled.div`
  margin-top: 60px;
`;

export const HamburgerMenu = styled.div`
  display: none;
  position: fixed;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  background-color: white;
  border-radius: 0 20% 20% 20%;
  z-index: 1000;

  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(40px);

  @media (max-width: 1140px) {
    ${({ open }) => open && `display: flex;`}
    text-align: center;
    margin: 5px;
    padding-left: 8px;
    padding-right: 8px;
    overflow: hidden;
  }
`;

export const ContainerItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  justify-items: center;
  @media (max-width: 1140px) {
    display: none;
  }
`;

export const Item = styled.div`
  padding: 15px;
  font-family: Outfit;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #575757;
  :hover {
    color: rgba(177, 125, 249, 1);
    border-bottom: 6px solid rgba(177, 125, 249, 1);
    cursor: pointer;
  }
  :active {
    outline-color: transparent;
  }
  @media (max-width: 1140px) {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 15px;
    padding-bottom: 8px;
  }
`;

export const ContainerLogo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding-inline: 3rem;
`;

export const Logo = styled.img`
  width: 80%;
  height: 80%;
`;

export const HamburgerContainer = styled.div`
  position: absolute;
  left: 0px;
  width: 40px;
  height: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  @media (max-width: 1140px) {
    display: flex;
  }
`;

export const HamburgerIcon = styled.div`
  background-image: url(${icon});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;

  width: 25px;
  height: 25px;
  display: flex;

  :hover {
    cursor: pointer;
  }
  :active {
    transform: scale(0.9);
  }
`;
