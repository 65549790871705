import React from 'react';
import {
  Wrapper,
  InvitationLabel,
  Title,
  WrapperLine,
  Square,
  Line,
  Spacer,
  BackgroundImagesContainer,
  BackgroundImages,
} from './styled-components';
import image1 from '../../assets/background-section1-1.png';
import image2 from '../../assets/background-section1-2.png';
import image3 from '../../assets/background-section1-3.png';

export const Home = () => {
  return (
    <Wrapper id='home'>
      <BackgroundImagesContainer>
        <BackgroundImages imageUrl={image1} />
        <BackgroundImages imageUrl={image2} />
        <BackgroundImages imageUrl={image3} />
      </BackgroundImagesContainer>
      <InvitationLabel>Te invitamos a nuestra boda</InvitationLabel>
      <Spacer />
      <Title>Tomas y Johana</Title>
      <Spacer />
      <WrapperLine>
        <Square />
        <Line />
        <Square />
      </WrapperLine>
    </Wrapper>
  );
};
