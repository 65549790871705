import styled from 'styled-components';

export const ResponsiveContainer = styled.div`
  width: 100%;
  overflow: hidden;

  @media (min-width: 640px) {
    min-width: 640px;
  }
  @media (min-width: 768px) {
    min-width: 768px;
  }
  @media (min-width: 1024px) {
    min-width: 1024px;
  }
  @media (min-width: 1280px) {
    min-width: 1280px;
  }
  @media (min-width: 1536px) {
    min-width: 1536px;
  }
`;
