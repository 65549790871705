import React from 'react';
import {
  Wrapper,
  Container,
  Paragraph,
  FlowerLeftImage,
  FlowerRightImage,
} from './styled-components';

export const Gratitude = () => {
  return (
    <Wrapper id='about-us'>
      <FlowerLeftImage />
      <Container>
        <Paragraph>
          Realmente no necesitas a alguien para que te complete. Solo necesitas a alguien que te
          acepte completamente
        </Paragraph>
      </Container>
      <FlowerRightImage />
    </Wrapper>
  );
};
