import styled from 'styled-components';
import { Navbar } from '../components/Navbar';
import { Home } from '../components/Home';
import { AboutUS } from '../components/AboutUS';
import { Counter } from '../components/Counter';
import { Address } from '../components/Address';
import { Gifts } from '../components/Gifts';
import { Gratitude } from '../components/Gratitude';
import { Footer } from '../components/Footer';

export const WrapperApp = styled.div`
  width: 100%;
  height: 100%;
`;

function Pages() {
  return (
    <WrapperApp>
      <Navbar />
      <Home />
      <AboutUS />
      <Counter />
      <Address />
      <Gifts />
      <Gratitude />
      <Footer />
    </WrapperApp>
  );
}

export default Pages;
