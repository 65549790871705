import { Item } from './styled-components';

export const NavItem = ({ label, link, onClick }) => {
  const handleClick = () => {
    const element = document.getElementById(link);
    element && element.scrollIntoView({ behavior: 'smooth' });
    onClick();
  };

  return <Item onClick={handleClick}>{label}</Item>;
};
