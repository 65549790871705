import React from 'react';
import {
  Wrapper,
  InfoContainer,
  Title,
  MapContainer,
  SplashImage,
  Text,
  Line,
} from './styled-components';

const MAP_TITLE = 'Rancho San Julian';
const MAP_SRC =
  'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26893.54104770592!2d-115.52045238437499!3d32.58772039999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d77a1d6e59bcdb%3A0xf1944e9d41246799!2sRancho%20San%20Julian!5e0!3m2!1ses-419!2smx!4v1678767758892!5m2!1ses-419!2smx';

export const Address = () => {
  return (
    <Wrapper id='address'>
      <SplashImage />
      <InfoContainer>
        <Title>El gran dia...</Title>
        <Text>15 ABRIL 2023 | 7:00 PM</Text>
        <Line />
        <Text>{`Primera 378, Zona Urbana Xochimilco, \r\n21380 Mexicali, B.C.`}</Text>
        <Text>Por favor, sé puntual .No seas como la novia...</Text>
      </InfoContainer>
      <MapContainer
        title={MAP_TITLE}
        src={MAP_SRC}
        frameborder='0'
        style={{ border: 0 }}
        allowfullscreen
        loading='lazy'
        referrerpolicy='no-referrer-when-downgrade'
      />
    </Wrapper>
  );
};
