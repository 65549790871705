import styled from 'styled-components';
import splash from '../../assets/slpash-gifts.svg';
import { ResponsiveContainer } from '../../shared/styles/styled-components';

export const Wrapper = styled(ResponsiveContainer)`
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media (max-width: 1140px) {
    height: unset;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  width: 70%;
`;

export const Title = styled.label`
  font-family: Estylle Madison;
  font-weight: 400;
  font-size: 80px;
  line-height: 90px;
  text-align: center;
  color: #9583e4;
  @media (max-width: 1140px) {
    font-size: 53px;
    line-height: 70px;
  }
`;

export const Text = styled.p`
  font-family: Cutive Mono;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  white-space: pre-line;
  @media (max-width: 1140px) {
    font-size: 20px;
    line-height: 22px;
  }
`;

export const SplashImage = styled.div`
  position: absolute;
  right: -5px;
  background: url(${splash});
  background-size: 120%;
  background-repeat: no-repeat;
  background-position: center;
  height: 70vh;
  width: 40%;
  z-index: -1;
  opacity: 0.8;
  @media (max-width: 1140px) {
    width: 40%;
    right: 0px;
    background-size: 160%;
  }
`;

export const PaddsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  overflow: hidden;
  @media (max-width: 1140px) {
    margin-top: 7vh;
    margin-bottom: 10vh;
  }
`;

export const GiftsContainer = styled.div`
  width: 98%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding-left: 50px;
  overflow-x: scroll;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Padd = styled.div`
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.1);
  transition: background-color 100ms linear;
  :hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.25);
  }
  :active {
    background-color: rgba(149, 131, 228, 0.8);
  }

  @media (max-width: 1140px) {
    width: 70px;
  }

  ${({ right }) =>
    right &&
    `
    right: 0;
    border-radius: 50% 0 0 50%;
  `}
  ${({ left }) =>
    left &&
    `
    left: 0;
    border-radius: 0 50% 50% 0;
  `}
`;

export const PaddDirection = styled.div`
  width: 30px;
  height: 30px;
  clip-path: polygon(50% 0, 0 50%, 100% 50%);
  background-color: rgba(255, 255, 255, 1);
  ${({ right }) =>
    right &&
    `
    transform: rotate(0.25turn);
  `}
  ${({ left }) =>
    left &&
    `
    transform: rotate(0.75turn);
  `}
`;

export const GiftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 10px;
  margin-left: 30px;
  margin-right: 30px;
  :hover {
    cursor: pointer;
  }
`;

export const GiftImage = styled.div`
  width: 250px;
  height: 250px;
  border-radius: 100%;
  background: url(${(props) => props.imageUrl});
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(255, 255, 255, 1);
  ${({ purchased }) =>
    purchased &&
    `
  filter: contrast(0.8) grayscale(50%) brightness(0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    padding: 3px;
    background-color: rgba(255, 255, 255, 0.7);
    font-size: 50px;
    font-weight: 400;
    color: rgba(180, 0, 0, 1);
    border-radius: 5px;
  }
  `}
`;

export const GiftProductName = styled.label`
  font-family: 'Cutive Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const GiftProductPrice = styled.label`
  font-family: 'Cutive Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #898989;
`;
