import styled from 'styled-components';
import { ResponsiveContainer } from '../../shared/styles/styled-components';
import image from '../../assets/background-section2.png';
import flowerLeft from '../../assets/flower-left.svg';
import flowerRight from '../../assets/flower-right.svg';

export const Wrapper = styled(ResponsiveContainer)`
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  position: relative;
`;

export const Container = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Paragraph = styled.p`
  font-family: Sarmilla;
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 65px;
  text-align: center;
  color: #f9f9f9;
  @media (max-width: 1140px) {
    font-size: 36px;
    line-height: 36px;
  }
`;

export const FlowerLeftImage = styled.div`
  position: absolute;
  left: -70px;
  bottom: -28px;
  background: url(${flowerLeft});
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
  height: 50vh;
  width: 30%;
  @media (max-width: 1140px) {
    display: none;
  }
`;

export const FlowerRightImage = styled.div`
  position: absolute;
  right: -70px;
  bottom: -18px;
  background: url(${flowerRight});
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
  height: 50vh;
  width: 30%;
  @media (max-width: 1140px) {
    display: none;
  }
`;
