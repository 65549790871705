import styled from 'styled-components';
import { ResponsiveContainer } from '../../shared/styles/styled-components';
import image from '../../assets/background-counter.png';

export const Wrapper = styled(ResponsiveContainer)`
  background-image: url(${image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 25vh;
  display: flex;
  box-shadow: inset 0px 4px 90px rgba(0, 0, 0, 0.25);
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  width: 65%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 1140px) {
    width: 95%;
  }
`;

export const WrapperNumber = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Number = styled.label`
  font-family: Quattrocento Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 76px;
  line-height: 90px;
  text-align: center;
  color: #ffffff;
  @media (max-width: 1140px) {
    font-size: 48px;
    line-height: 53px;
  }
`;

export const Label = styled.label`
  font-family: Quattrocento Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  @media (max-width: 1140px) {
    font-size: 14px;
    line-height: 16px;
  }
`;
