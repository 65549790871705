import React, { useCallback, useEffect, useState } from 'react';
import { Wrapper, Container, WrapperNumber, Number, Label } from './styled-components';

const WEDDING_DATE = '2023-04-15T19:00:00.000-08:00';
const REFRESH_TIMER_EVERY = 1000; // 1 second

const LABELS = {
  days: 'DÍAS',
  hours: 'HORAS',
  minutes: 'MINUTOS',
  seconds: 'SEGUNDOS',
};

let timer;
export const Counter = () => {
  const [leftTime, setLeftTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const setTime = useCallback(() => {
    let wedding_date = new Date(WEDDING_DATE);
    wedding_date = new Date(wedding_date.toISOString());
    wedding_date = wedding_date.getTime();

    let now_date = new Date();
    now_date = new Date(now_date.toISOString());
    now_date = now_date.getTime();

    let delta = Math.abs(wedding_date - now_date) / 1000;

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    const seconds = parseInt(delta % 60);

    setLeftTime({
      days,
      hours,
      minutes,
      seconds,
    });
  }, []);

  const initCounter = useCallback(() => {
    timer = setTimeout(() => {
      setTime();
      initCounter();
    }, REFRESH_TIMER_EVERY);
  }, [setTime]);

  useEffect(() => {
    setTime();
    initCounter();

    return () => timer && window.clearTimeout(timer);
  }, [initCounter, setTime]);

  return (
    <Wrapper id='counter'>
      <Container>
        {Object.keys(leftTime)?.map((keyTime) => (
          <WrapperNumber key={keyTime}>
            <Number>{leftTime[keyTime]}</Number>
            <Label>{LABELS[keyTime]}</Label>
          </WrapperNumber>
        ))}
      </Container>
    </Wrapper>
  );
};
