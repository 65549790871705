import styled from 'styled-components';
import { ResponsiveContainer } from '../../shared/styles/styled-components';

export const Wrapper = styled(ResponsiveContainer)`
  width: 100%;
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 8px solid #937ee4;
  position: relative;
  @media (max-width: 1140px) {
    height: 85vh;
  }
`;

export const BackgroundImagesContainer = styled(ResponsiveContainer)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0px;
  position: absolute;
  z-index: -1;

  @media (max-width: 1140px) {
    @keyframes slideone {
      0%,
      20% {
        transform: translateX(0%);
      }
      40%,
      60% {
        transform: translateX(-100%);
      }
      80%,
      100% {
        transform: translateX(-200%);
      }
    }
    @keyframes slidetwo {
      0%,
      20% {
        transform: translateX(100%);
      }
      40%,
      60% {
        transform: translateX(0%);
      }
      80%,
      100% {
        transform: translateX(-100%);
      }
    }
    @keyframes slidethree {
      0%,
      20% {
        transform: translateX(200%);
      }
      40%,
      60% {
        transform: translateX(100%);
      }
      80%,
      100% {
        transform: translateX(0%);
      }
    }

    div:nth-child(1) {
      transform: translateX(0%);
      animation-name: slideone;
    }
    div:nth-child(2) {
      transform: translateX(100%);
      animation-name: slidetwo;
    }
    div:nth-child(3) {
      transform: translateX(100%);
      animation-name: slidethree;
    }
    div {
      animation: 20s ease-in-out infinite alternate;
    }
  }
`;

export const BackgroundImages = styled.div`
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 33.33%;
  height: 100%;
  border: 0px;

  @media (max-width: 1140px) {
    width: 100%;
    position: absolute;
  }
`;

export const InvitationLabel = styled.label`
  font-family: Cutive Mono;
  font-size: 30px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  @media (max-width: 1140px) {
    font-size: 20px;
    line-height: 22px;
    color: #ffffff;
  }
`;

export const Title = styled.label`
  font-family: Estylle Madison;
  font-size: 200px;
  font-weight: 400;
  line-height: 250px;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(311.3deg, #937ee4 5.94%, #a6afe7 92.15%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  padding-inline: 20px;
  @media (max-width: 1140px) {
    font-size: 70px;
    line-height: 90px;
  }
`;

export const WrapperLine = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1140px) {
    display: none;
  }
`;

export const Square = styled.div`
  background-color: #979797;
  height: 10px;
  width: 10px;
  transform: rotate(45deg);
`;

export const Line = styled.div`
  width: 242px;
  height: 2px;
  background-color: #979797;
`;

export const Spacer = styled.div`
  height: 15px;
`;
