import React from 'react';
import { Wrapper, Container, Text, TextSmall } from './styled-components';

export const Footer = () => {
  return (
    <Wrapper id='about-us'>
      <Container>
        <Text>By Tomas & Johana</Text>
        <TextSmall>❤️</TextSmall>
      </Container>
    </Wrapper>
  );
};
