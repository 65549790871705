import styled from 'styled-components';
import splash from '../../assets/splash.svg';

export const Wrapper = styled.div`
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(191, 191, 191, 0.4);
  position: relative;
  @media (max-width: 1140px) {
    height: unset;
    flex-direction: column;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 1140px) {
    margin-top: 40px;
  }
`;

export const MapContainer = styled.iframe`
  width: 586px;
  height: 588px;
  @media (max-width: 1140px) {
    width: 100%;
    margin-top: 30px;
  }
`;

export const Title = styled.label`
  font-family: Estylle Madison;
  font-weight: 400;
  font-size: 80px;
  line-height: 81px;
  text-align: center;
  color: #9583e4;
  @media (max-width: 1140px) {
    font-size: 55px;
    line-height: 65px;
  }
`;

export const Text = styled.p`
  font-family: Cutive Mono;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  white-space: pre-line;
  @media (max-width: 1140px) {
    font-size: 20px;
    line-height: 22px;
  }
`;

export const Line = styled.div`
  width: 150px;
  height: 1px;
  background-color: #898989;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 1140px) {
    width: 120px;
  }
`;

export const SplashImage = styled.div`
  position: absolute;
  left: -60px;
  top: -90px;
  background: url(${splash});
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;
  height: 50vh;
  width: 30%;
  z-index: -1;
`;
