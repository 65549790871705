import React, { useEffect, useState, useCallback } from 'react';
import {
  Wrapper,
  Title,
  SplashImage,
  Text,
  HeaderContainer,
  GiftsContainer,
  GiftWrapper,
  GiftImage,
  GiftProductName,
  GiftProductPrice,
  PaddsContainer,
  Padd,
  PaddDirection,
} from './styled-components';
import raw from './giftAmazon.txt';
import { Tooltip } from '../../shared/components/Tooltip';
import { isValidUrl } from '../../utils/validations';

const AMAZON_HOST = 'https://www.amazon.com.mx';
const STEP_SCROLL = 400;

export const Gifts = () => {
  const [giftProducts, setGiftProducts] = useState();

  const formatLink = useCallback((strUrl) => {
    const url = new URL(strUrl);
    strUrl = strUrl.replace(url?.origin, AMAZON_HOST);

    if (!isValidUrl(strUrl)) return null;

    return strUrl;
  }, []);

  const mapProductsFromNodeHtml = useCallback(
    (node) => {
      let productsElements = node.getElementsByClassName('wedding-guest-view__grid')[0].children;
      const productLength = productsElements.length;
      const mappedProducts = [];

      for (let index = 0; index < productLength; index++) {
        const element = productsElements[index];

        // REPLACING ORIGIN
        let strUrl = element.children[1].children[0].children[0].href;
        strUrl = formatLink(strUrl);

        // FORMATTING PRICE
        const strPrice = element.children[2].children[0].children[0].innerText;
        const options = { style: 'currency', currency: 'USD' };
        const numberFormat = new Intl.NumberFormat('en-US', options);
        const price = parseFloat(strPrice.match(/\d/g)?.join('')) / 100;

        mappedProducts.push({
          name: element.children[1].children[0].children[0].ariaLabel,
          link: strUrl,
          image: element.children[1].children[0].children[2].children[0].src,
          price: numberFormat.format(price),
          purchased: element.children[1].children[0]?.children?.[1]?.classList?.contains(
            'registry-asin-card__label--purchased',
          ),
        });
      }
      setGiftProducts(mappedProducts);
    },
    [formatLink],
  );

  useEffect(() => {
    fetch(raw)
      .then((r) => r.text())
      .then((text) => {
        const node = new DOMParser().parseFromString(text, 'text/html').body.firstElementChild;
        mapProductsFromNodeHtml(node);
      });
  }, [mapProductsFromNodeHtml]);

  const handleScroll = useCallback((right = false) => {
    const leftScroll = right ? STEP_SCROLL : STEP_SCROLL * -1;
    const node = document.getElementById('gifts-container');
    node.scrollLeft += leftScroll;
  }, []);

  return (
    <Wrapper id='gifts'>
      <SplashImage />
      <HeaderContainer>
        <Title>Mesa de Regalos</Title>
        <Text>
          Tu presencia será nuestro mejor regalo, aún así, si quieres contribuir en nuestra futura
          vida juntos, te dejamos una lista de regalos que nos ayudarían mucho. Pero esto es
          completamente opcional.
        </Text>
      </HeaderContainer>
      <PaddsContainer>
        <Padd left onClick={() => handleScroll(false)}>
          <PaddDirection left />
        </Padd>
        <GiftsContainer id='gifts-container'>
          {giftProducts?.map((product) => (
            <GiftWrapper
              onClick={() => Boolean(product?.link) && window.open(product.link, '_blank')}
            >
              <GiftImage imageUrl={product?.image} purchased={Boolean(product?.purchased)}>
                {Boolean(product?.purchased) && <Text>COMPRADO</Text>}
              </GiftImage>
              <Tooltip text={product?.name}>
                <GiftProductName>{product?.name?.slice(0, 25)}</GiftProductName>
              </Tooltip>
              <GiftProductPrice>{product?.price}</GiftProductPrice>
            </GiftWrapper>
          ))}
        </GiftsContainer>
        <Padd right onClick={() => handleScroll(true)}>
          <PaddDirection right />
        </Padd>
      </PaddsContainer>
    </Wrapper>
  );
};
