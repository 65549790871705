import styled from 'styled-components';
import { ResponsiveContainer } from '../../shared/styles/styled-components';

export const Wrapper = styled(ResponsiveContainer)`
  width: 100%;
  height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1f1b26;
  @media (max-width: 1140px) {
    height: 10vh;
  }
`;

export const Container = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const Text = styled.p`
  font-family: Sarmilla;
  font-size: 60px;
  line-height: 65px;
  text-align: center;
  color: #f9f9f9;
  @media (max-width: 1140px) {
    font-size: 30px;
    line-height: 36px;
  }
`;

export const TextSmall = styled.label`
  margin-left: 20px;
  font-size: 20px;
  @media (max-width: 1140px) {
    margin-left: 10px;
    font-size: 10px;
  }
`;
