import { useCallback, useState } from 'react';
import { NavItem } from './Item';
import options from './options.json';
import {
  Nav,
  NavSpaceContent,
  ContainerItem,
  ContainerLogo,
  Logo,
  HamburgerContainer,
  HamburgerIcon,
  HamburgerMenu,
} from './styled-components';
import image from '../../assets/heart.svg';

export const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenHamburgerMenu = useCallback(() => {
    setOpenMenu((prev) => !Boolean(prev));
  }, []);

  const handleItemClick = useCallback(() => {
    setTimeout(handleOpenHamburgerMenu, 400);
  }, [handleOpenHamburgerMenu]);

  return (
    <>
      <Nav>
        <HamburgerContainer>
          <HamburgerIcon onClick={handleOpenHamburgerMenu} />
        </HamburgerContainer>
        <ContainerLogo>
          <Logo src={image} alt='logo' />
        </ContainerLogo>
        <ContainerItem>
          {options.map((option) => (
            <NavItem key={option.label} {...option} />
          ))}
        </ContainerItem>
      </Nav>
      <NavSpaceContent />
      <HamburgerMenu open={openMenu}>
        {options.map((option) => (
          <NavItem key={option.label} onClick={handleItemClick} {...option} />
        ))}
      </HamburgerMenu>
    </>
  );
};
