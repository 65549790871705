import React from 'react';
import {
  Wrapper,
  Container,
  WrapperImage,
  ImageOfUs,
  WrapperText,
  TitlePrimary,
  Paragraph,
  FlowerUpperImage,
  FlowerImage,
} from './styled-components';

export const AboutUS = () => {
  return (
    <Wrapper id='about-us'>
      <Container>
        <FlowerUpperImage />
        <WrapperImage>
          <ImageOfUs />
        </WrapperImage>
        <WrapperText>
          <TitlePrimary>Nos Casamos</TitlePrimary>
          <Paragraph>
            Después de mucho tiempo juntos, esto no podía terminar de otra manera… ¡Nos casamos!
            {`\r\n\r\n`}
            Contigo hemos vivido muchos de esos momentos que nos definen tal y como somos, por eso
            nos encantaría que nos acompañaras en el primer día del resto de nuestras vidas.
          </Paragraph>
        </WrapperText>
        <FlowerImage />
      </Container>
    </Wrapper>
  );
};
