import styled from 'styled-components';

export const ToolTipText = styled.span`
  visibility: hidden;
  background-color: rgb(223, 218, 227, 0.9);
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 10%;
  left: 50%;
  margin-left: -60px;
  :after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;

export const ToolTip = styled.div`
  position: relative;
  display: inline-block;
  :hover span {
    visibility: visible;
  }
`;
