import styled from 'styled-components';
import { ResponsiveContainer } from '../../shared/styles/styled-components';
import image from '../../assets/circle-photo.png';
import flowerUpper from '../../assets/flower-upper-left.svg';
import flowerBottom from '../../assets/flower-bottom-right.svg';

export const Wrapper = styled(ResponsiveContainer)`
  width: 100%;
  height: 70vh;
  box-shadow: inset 0px 4px 90px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  @media (max-width: 1140px) {
    height: unset;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1140px) {
    flex-direction: column-reverse;
  }
`;

export const WrapperImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 230px;
  @media (max-width: 1140px) {
    margin-left: 0px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
`;

export const ImageOfUs = styled.div`
  background: url(${image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  clip-path: circle(50%);
  filter: drop-shadow(5px 4px 50px rgba(0, 0, 0, 0.25));
  width: 45vh;
  height: 45vh;
  @media (max-width: 1140px) {
    width: 25vh;
    height: 25vh;
  }
`;

export const WrapperText = styled.label`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin-left: 50px;
  @media (max-width: 1140px) {
    margin-top: 65px;
    margin-left: 0;
    width: 70%;
    align-items: center;
  }
`;

export const TitlePrimary = styled.label`
  font-family: Estylle Madison;
  font-size: 90px;
  font-weight: 400;
  line-height: 97px;
  letter-spacing: 0em;
  text-align: center;
  color: #9583e4;
  @media (max-width: 1140px) {
    font-size: 64px;
    line-height: 70px;
  }
`;

export const Paragraph = styled.p`
  font-family: Cutive Mono;
  font-size: 24px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: justify;
  color: #8c8b8d;
  white-space: pre-line;
  @media (max-width: 1140px) {
    font-size: 20px;
    line-height: 22px;
    text-align: center;
  }
`;

export const FlowerUpperImage = styled.div`
  position: absolute;
  left: -50px;
  top: -150px;
  background: url(${flowerUpper});
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
  height: 50vh;
  width: 30%;
  z-index: -1;
  @media (max-width: 1140px) {
    transform: rotateY(180deg);
    background-size: 150%;
    left: unset;
    top: -140px;
    right: 0px;
  }
`;

export const FlowerImage = styled.div`
  position: absolute;
  right: -70px;
  bottom: -90px;
  margin-bottom: -30px;
  background: url(${flowerBottom});
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
  height: 55vh;
  width: 30%;
  z-index: -1;
  @media (max-width: 1140px) {
    display: none;
  }
`;
